<template>
    <div class="Activity">
		<Header></Header>
		<div class="main">
			<div class="mainLeft" :style="{'width': isCollapse?'339px':'59px','height':height}">
				<div class="headerBox" v-if="!isCollapse">
					<div class="headerIcon" @click="isCollapse = !isCollapse">
						<el-icon><Back /></el-icon>
					</div>
				</div>
				<div class="headerBox" v-if="isCollapse">
					<div class="header">
						<div class="hearderL">
							<el-icon><Histogram /></el-icon>Filter
						</div>
						<div class="hearderR" @click="isCollapse = !isCollapse">
							<el-icon :size="18" color="rgba(4, 17, 29, 0.5);"><Back /></el-icon>
						</div>
					</div>
					<div>
						<el-collapse v-model="activeNames">
							<el-collapse-item title="Consistency" name="1">
								1
							</el-collapse-item>
							<el-collapse-item title="Consistency" name="2">
								2
							</el-collapse-item>
							<el-collapse-item title="Consistency" name="3">
								3
							</el-collapse-item>
						</el-collapse>
					</div>
				</div>
			</div>
			<div class="mainRight">
				<div class="mainRightBox">
					<div class="searchTitle">
						<div class="box">
							搜索 <el-icon><CloseBold /></el-icon>
						</div>
						<span>清除所有</span>
					</div>
					<div class="boxListCon">
						<el-table v-if="showNode" :data="tableData" style="width: 100%">
							<el-table-column prop="date" width="120">
								<div class="cart">
									<el-icon :size="20"><ShoppingCartFull /></el-icon>
									Sale
								</div>
							</el-table-column>
							<el-table-column prop="name" label="Item">
								<div class="user">
									<span>
										<img src="../../../assets/img/use0.png">
									</span>
									<span class="text">
										<div>Abuse_Voice365</div>
										<div>01/13Abuse_Voice</div>
									</span>
								</div>
							</el-table-column>
							<el-table-column prop="address" label="Price" width="150">
								<div style="font-weight:bold;font-size:16px;">0.168</div>
								<div>$4.65</div>
							</el-table-column>
							<el-table-column prop="" label="Quantity" width="150" />
							<el-table-column prop="address" label="From" width="200" />
							<el-table-column prop="address" label="To" width="200" />
							<el-table-column prop="time" label="Time" width="250" />
						  </el-table>
					</div>
				</div>
			</div>
		</div>
		<!-- <Footer></Footer> -->
    </div>
</template>
<script>
	import Header from "@/components/Header"
	import Footer from "@/components/Footer"
	import { Histogram,Back,CloseBold,ShoppingCartFull } from '@element-plus/icons-vue'
	export default {
		components: { Header,Footer,Histogram,Back,CloseBold,ShoppingCartFull },
		data() {
			return{
				showNode:true,
				isCollapse:true,
				width:"59px",
				height:'200px',
				activeNames:'1',
				tableData:[
					{
						date: '2016-05-03',
						name: 'Tom',
						address: '1',
						time: '10s',
					},
					{
						date: '2016-05-03',
						name: 'Tom',
						address: '1',
						time: '10s',
					},
					{
						date: '2016-05-03',
						name: 'Tom',
						address: '1',
						time: '10s',
					},
					{
						date: '2016-05-03',
						name: 'Tom',
						address: '1',
						time: '10s',
					}
				]
			}
		},
		watch: {
			isCollapse(newValue, oldValue) {
				if(newValue){
					this.showNode = false;
					setTimeout(()=>{
						this.showNode = true;
					},50)
				}
			}
		},
		beforeMount(){
		  this.setHeight()
		},
		methods:{
			setHeight(){
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				this.height = h - 72 + 'px';
			}
		},
		mounted(){
			
		},
	};
</script>
<style scoped>
	.main{
		width:100%;
		display: flex;
	}
	.mainLeft{
		height:100%;
		border-right: 1px solid rgb(229, 232, 235);
		box-sizing: border-box;
		position: sticky;
		bottom: 0px;
		top: 72px;
		flex-shrink: 0;
		background-color: rgb(255, 255, 255);
		display: inline-block;
	}
	.header{
		height: 60px;
		padding: 16px;
		border-bottom: 1px solid rgb(229, 232, 235);
		box-sizing: border-box;
		font-size:16px;
		font-weight: bold;
	}
	.header:hover{
		box-shadow: -5px 4px 11px -2px #6b6b6b;
	}
	.header .hearderL .el-icon{
		margin-right: 10px;
		transform:rotate(180deg);
		vertical-align: text-bottom;
	}
	.hearderL{
		width:50%;
		display: inline-block;
		justify-content: center;
		align-items: center;
		vertical-align: sub;
	}
	.hearderR{
		width:50%;
		text-align: right;
		display: inline-block;
		justify-content: center;
		align-items: center;
		vertical-align: sub;
		padding-right: 10px;
		box-sizing: border-box;
	}
	.mainRight{
		width:100%;
		display: inline-block;
	}
	.headerIcon{
		width:59px;
		height:60px;
		justify-content: center;
		align-items: center;
		display: flex;
	}
	.headerIcon .el-icon{
		transform: rotate(180deg);
		font-size: 22px;
		font-weight:bold;
	}
	.headerIcon:hover{
		box-shadow: -5px 4px 11px -2px #6b6b6b;
	}
	.searchTitle{
		margin-bottom:15px;
		padding:20px;
		border-bottom: 1px solid rgb(229, 232, 235);
	}
	.searchTitle .box{
		padding :15px;
		border:1px solid rgb(21, 178, 229);
		background:rgba(21, 178, 229, 0.06);
		display: inline-block;
		border-radius:10px;
		margin-right:15px;
	}
	.searchTitle .box .el-icon{
		vertical-align: text-top;
	}
	.searchTitle span{
		color: rgb(32, 129, 226);
	}
	.boxListCon{
		padding:0px 20px;
	}
	.boxListCon .cart{
		font-size:16px;
		font-weight:bold;
	}
	.boxListCon .cart .el-icon{
		vertical-align: sub;
	}
	.user span{
		display: inline-block;
	}
	.user span:first-child{
		vertical-align: bottom;
	}
	.user .text div:first-child{
		color:#909399;
	}
	.user .text div:last-child{
		color:#000;
		font-weight:bold;
	}
	.user img{
		width:48px;
		height:48px;
		border-radius: 10px;
		vertical-align: text-top;
		margin-right:8px;
	}
</style>
